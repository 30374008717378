<template>
    <modal :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal">
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                        <input type="text" class="form-control" v-model="form.name" :disabled="statusEdit == false">
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('type') }}</h4>
                        <Multiselect
                            v-model="form.type"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"
                            :options="listType"
                            :disabled="statusEdit == false"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Required') }}</h4>
                        <Multiselect
                            v-model="form.required"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"
                            :options="listRequired"
                            :disabled="statusEdit == false"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('description') }}</h4>
                        <input type="text" class="form-control" v-model="form.comment" :disabled="statusEdit == false">
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-danger" @click="remove()">{{ $t('Delete') }}</button>
            <button type="button" class="btn btn-success" @click="statusEdit == false ? statusEdit = true : edit()">{{ statusEdit == false ? $t('Edit') : $t('save') }}</button>
        </template>
    </modal>
 </template>
 
<script>
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { ApiDocs } from '@/API.js';
import { storeS } from '@/store.js' 
 
 let apiServe = new ApiDocs();
 
export default ({
    props: ['objCard', 'ifPrevopen', 'ifNextopen',],
    data(){
        return {
            form: {
                name: "",
                type: "",
                required: 1,
                comment: ""
            },
            statusEdit: false,
            listRequired: [
                { label: this.$t('Required'), value: '1' },
                { label: this.$t('NotRequired'), value: '0' }
            ],
            listType: [
                { label: 'array', value: 'array' },
                { label: 'boolean', value: 'boolean' },
                { label: 'int', value: 'int' },
                { label: 'number', value: 'number' },
                { label: 'object', value: 'object' },
                { label: 'string', value: 'string' },
                { label: 'null', value: 'null' },
            ]
        }
    },
    components: { modal, Multiselect },
    created(){
        this.form = this.objCard
    },
    methods: {
        prevOpen(){
            //пред заявка
            this.$emit('close');
            this.$emit('prevOpen', this.form.attributeId)
        },
        nextOpen(){
            //след заявка
            this.$emit('close');
            this.$emit('nextOpen', this.form.attributeId)
        },
        edit() {
            apiServe.editAttribute(this.form.attributeId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('close')
                    this.eventBus.emit('saveAttr', true)
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },  
        remove() {
            apiServe.deleteAttribute(this.form.attributeId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                    this.eventBus.emit('saveAttr', true)
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        } 
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>